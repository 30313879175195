<template>
  <div>
    <div class="search">
      <div class="title">套餐列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="套餐名称:">
          <el-input v-model="params.packName"></el-input>
        </el-form-item>

        <el-form-item label="状态:">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="上架" :value="1"></el-option>
            <el-option label="下架" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button
            type="primary"
            @click="$router.push('/foodPackAdd?foodId=' + params.foodId)"
            >添加</el-button
          >
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
          <el-button type="primary" @click="$router.push('/foodList')">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      align="center"
    >
      <el-table-column
        type="selection"
        width="55"
        :reserve-selection="true"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="packId" label="ID" align="center"> </el-table-column>

      <el-table-column label="排序" align="center" width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.dept" @change="changeSort(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="packName" label="套餐名称" align="center"> </el-table-column>
      <el-table-column prop="foodName" label="所属商品" align="center"> </el-table-column>
      <el-table-column prop="cateName" label="所属分类" align="center"> </el-table-column>
      <el-table-column label="展示图" width="110" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.showImg"
            :preview-src-list="[scope.row.showImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="fee" label="售价" width="80" align="center">
      </el-table-column>
      <el-table-column prop="label" label="标签" width="110" align="center">
      </el-table-column>
      <el-table-column prop="sellCount" label="销量" width="110" align="center">
      </el-table-column>
      <el-table-column prop="strStatus" label="状态" width="80" align="center">
      </el-table-column>

      <el-table-column prop="addTime" label="创建时间" width="170" align="center">
      </el-table-column>
      <el-table-column label="操作" width="450" align="center">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            v-if="scope.row.status == 2"
            @click="upData(scope.row, 1)"
            >上架</el-button
          >
          <el-button
            type="warning"
            size="mini"
            v-if="scope.row.status == 1"
            @click="upData(scope.row, 2)"
            >下架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="
              $router.push(
                '/foodPackAdd?foodId=' + params.foodId + '&packId=' + scope.row.packId
              )
            "
            >编辑</el-button
          >
         
          <el-button
            type="primary"
            size="mini"
            @click="$router.push('/foodPackListwrite?packId=' + scope.row.packId)"
            >核销点管理</el-button
          >

          <el-button
            type="primary"
            size="mini"
            @click="$router.push('/foodPackStock/' + scope.row.packId)"
            >设置库存</el-button
          >
          <el-dropdown @command="handleCommand($event,scope.row)" style="margin-left:10px">
          <el-button type="primary" size="mini">
            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">一键复制</el-dropdown-item>
            <el-dropdown-item :command="2">查看页面路径</el-dropdown-item>
            <!-- <el-dropdown-item :command="3">去拼团</el-dropdown-item>
            <el-dropdown-item :command="4">去秒杀</el-dropdown-item> -->
            <el-dropdown-item :command="5">查看日志</el-dropdown-item>
            <el-dropdown-item :command="6">删除</el-dropdown-item>
            <el-dropdown-item :command="7">生成二维码</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <div>
        <el-button type="success" @click="upData('', 1)">批量上架</el-button>
        <el-button type="warning" @click="upData('', 2)">批量下架</el-button>
        <el-button type="primary" @click="remove('')">批量删除</el-button>
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="查看页面地址" :visible.sync="showAddress" width="35%">
      <div id="address">
        页面地址:<el-input v-model="ticketAddress" readonly id="wrapper"></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="copyinviteUrl"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  foodPackList,
  foodPackListUpData,
  foodPackListDel,
  foodPackListSort,
  createErFoodPack,
  foodListPackCopy
} from "../../api/food.js";
import { isActivity } from "../../api/groupBuy";

import { isActivityKill } from "../../api/seckillInfo";
export default {
  data() {
    return {

      codeVisible: false,
      QRcodeSize: "",
      packId:'',
      imgUrl: "",



      dialogVisible: false,
      total: 0,
      fatherId: "",
      ticketAddress: "",
      showAddress: false,
      params: {
        currentPage: 1,
        pageSize: 5,
        packName: "",
        foodId: 0,
        status: 0,
      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      ids: "",
      cateList: [],

      cateListSun: [],
      addRules: {
        tableData: [
          { required: true, message: "请输入活动名称", trigger: ["blur", "change"] },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.$route.query.foodId ? (this.params.foodId = this.$route.query.foodId) : "";
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.packId;
    },
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.packId).join(",");
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await foodPackList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    remove(row) {
      if (!row) {
        if (!this.ids) {
          this.$message.warning("请选择要操作的商品");
          return;
        }
      }
      this.$confirm(`此操作将删除商品, 是否继续?, 提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          foodPackListDel({
            ids: row ? row.packId : this.ids,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message.success("操作成功");

              this.getList();
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    upData(row = "", status) {
      let str = "";
      if (!row) {
        if (!this.ids) {
          this.$message.warning("请选择要操作的商品");
          return;
        }
      }
      switch (status) {
        case 1:
          str = "上架";
          break;
        case 2:
          str = "下架";
          break;
      }

      this.$confirm(`此操作将${str}该商品, 是否继续?, 提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          foodPackListUpData({
            status,
            ids: row ? row.packId : this.ids,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message.success("操作成功");

              this.getList();
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    edit(row) {
      this.$router.push({
        path: "/foodAdd",
        query: {
          foodId: row.foodId,
        },
      });
    },
    changeSort(row) {
      foodPackListSort({
        dept: row.dept,
        packId: row.packId,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("设置成功");
          this.getList();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },

    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    handleCommand(e, row) {
      console.log(e, row);
      if (e == 1) {
       this.copy(row.packId)
      } else if (e == 2) {
       this.onShowAddress(row.packId)
      }else if (e == 3) {
       this.onGOGroupBuy(row.packId)
      }else if (e == 4) {
       this.onGOSecKill(row.packId)
      }else if (e == 5) {
       this.$router.push('/foodPackLog/'+row.packId)
      }else if (e == 6) {
       this.remove(row)
      }else if (e == 7) {
       this.creatCode(row.packId)
      }
    },
    creatCode(id) {
      this.packId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createErFoodPack({
          packId: this.packId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    copy(packId){
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await foodListPackCopy({
            packId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onShowAddress(id) {
      this.ticketAddress =
        "fineFood/pages/package/package?packId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },
    async onGOSecKill(packId) {
      const { data } = await isActivityKill({
        prodType: 2,
        prodId: packId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addSeckill/0/${packId}/${2}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async onGOGroupBuy(packId) {
      const { data } = await isActivity({
        prodType: 2,
        prodId: packId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addGroupBuy/0/${packId}/${2}`);
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
